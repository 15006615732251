import { objectKeysToCamelCase } from '../../../core/utils/caseConversion';
import { useMemo } from 'react';
import { convertPythonDateTimeFormat } from '../../../utils/dates';
import { camelCase, cloneDeep } from 'lodash';

export const GROUP_SEPARATOR = '__';
export const DEFAULT_VALUE_KEY = 'value';

export const useDocumentConfig = (documentConfig: any, prefixes: string[] = []) => {
    // global document config
    // TODO: consider default values ?
    const flatOptions = useMemo(() => {
        const generalConfig: any = objectKeysToCamelCase(
            Object.fromEntries((documentConfig?.options || []).map((option) => [option.name, option.value]))
        );

        if (generalConfig?.dateFormat) {
            generalConfig.dateFormat = convertPythonDateTimeFormat(generalConfig.dateFormat);
        }

        return generalConfig;
    }, [documentConfig]);

    const fieldConfigsByPrefix = useMemo(() => {
        // field specific config
        let fieldConfigs = documentConfig?.fields || [];
        fieldConfigs = [...fieldConfigs.filter((fieldConfig) => fieldConfig.enabled)];
        fieldConfigs.sort((a, b) => a.position - b.position);

        const fieldConfigsByPrefix = Object.fromEntries(prefixes.map((prefix) => [prefix, []]));
        fieldConfigsByPrefix[''] = []; // default prefix for header data

        fieldConfigs.forEach((fieldConfig) => {
            const { name, valueType, position, options } = fieldConfig;

            // ignore field names that are the prefix itself
            if (prefixes.includes(name)) return;

            const prefix = prefixes.find((prefix) => name.startsWith(`${prefix}${GROUP_SEPARATOR}`)) || '';

            // TODO: consider default values ?
            const flatOptions: any = objectKeysToCamelCase(
                Object.fromEntries(options.map((option) => [option.name, option.value]))
            );

            if (flatOptions?.dateFormat) {
                flatOptions.dateFormat = convertPythonDateTimeFormat(flatOptions.dateFormat);
            }

            fieldConfigsByPrefix[prefix].push({ name, valueType, position, ...flatOptions });
        });

        return fieldConfigsByPrefix;
    }, [documentConfig, prefixes.join(',')]);

    return { generalConfig: flatOptions, fieldConfigsByPrefix };
};

interface FormFieldData {
    path: string;

    bbox: number[][];
    pageIndex: number;

    confidenceExplanation?: any;
    predictionConfidence: number;

    validationChecks?: any[];

    __typename?: string;

    // value stuff
    value?: any;
    [key: string]: any;
}

interface FormFieldConfigInData {
    // Really bad design: This is a mix of config and value data
    isComposite?: boolean;
    isDisplayed?: boolean;
    summaryValue?: any;
    options?: any[];
    choices?: any[];
}

export interface MatchingLookupProps {
    hasMatching: true;
    minSearchChars: number;
    fetchOptions: any;
    renderOptions: any;
    extraSearchTerms: any;
    matchingDocumentNumber: string;
    lookupTypeDefault?: string;
    lookupDefinitionIdDefault?: string;
    lookupFieldNameDefault?: string;
    lookupLastSuccessfulImportDate?: string;
    documentNumberFieldName?: string;
    searchLookupOnEmptyValue?: string;
}

export interface FormFieldConfig extends MatchingLookupProps {
    // base
    name: string;
    isRequired: boolean;
    position: number;
    valueType: string;
    // lookup stuff
    allowInvalidIds?: boolean;
    lookupDefinitionId?: string;
    lookupFieldName?: string;
    lookupType?: string;
    performLookups?: boolean;
    // field specific
    autofillEmptyDeliveryDate?: boolean;
    ignoreConfidence?: boolean;
    useDeliveryDateRange?: boolean;
    // article specific
    debitorArticleWhenDifferentPerfectMatches?: boolean;
    keepExternalArticleNumber?: boolean;
    resetImperfectArticleMatches?: boolean;
    // date specific
    dateFormat?: string;
}

export interface FormField {
    /*
    In the form field interface we denormalize some data to make it easier to access and display.
    We store the field config and the field data in the same object as references.
    */

    prefix?: string; // prefix for field name e.g. 'line_items'
    fieldName: string; // field name without prefix e.g. 'delivery_address'

    // each form field can only display one value so we need to specify which value to display
    valueKey: string; // key for value in data e.g. 'value' or "street"
    valueType: string;
    value: any;

    // explanation and location -> needed for imagemap
    bbox: number[][];
    pageIndex: number;
    itemIndex?: number;

    // store the references for fast access
    config: FormFieldConfig;
    data: FormFieldData & FormFieldConfigInData;
}

const getFieldPath = (prefix: string, itemIndex: number | undefined | null, fieldName: string, valueKey: string) => {
    let path = prefix ? `${prefix}${GROUP_SEPARATOR}` : '';
    if (itemIndex !== undefined && itemIndex !== null) {
        path += `${itemIndex}${GROUP_SEPARATOR}`;
    }
    path += `${fieldName}`;
    if (valueKey !== DEFAULT_VALUE_KEY && valueKey !== undefined) {
        path += `${GROUP_SEPARATOR}${valueKey}`;
    }
    return path;
};

export const getFormFieldGroups = (
    parent: any,
    fieldConfigs: any[],
    prefix: string = '',
    itemIndex = undefined
): FormField[][] => {
    const groups = {};

    // field configs (one for group level + one per valueKey/"form field")
    fieldConfigs.forEach((fieldConfig) => {
        let fieldConfigName = fieldConfig.name;

        // remove prefixes from field names e.g. 'line_items__name' -> 'name'
        if (prefix && fieldConfigName.startsWith(`${prefix}${GROUP_SEPARATOR}`)) {
            fieldConfigName = fieldConfigName.replace(`${prefix}${GROUP_SEPARATOR}`, '');
        }

        const [fieldName, valueKey] = fieldConfigName.split(GROUP_SEPARATOR);

        const fieldData = parent?.[camelCase(fieldName)] || {};

        // Trox customization shit
        const fieldValue =
            fieldData?.[camelCase(valueKey || 'value')] || fieldData?.summaryValue || fieldData?.codeString;

        // initialize new field group
        if (!groups[fieldName]) {
            groups[fieldName] = [];
        }

        groups[fieldName].push({
            path: getFieldPath(prefix, itemIndex, fieldName, valueKey),

            prefix,
            fieldName,

            valueKey: valueKey || DEFAULT_VALUE_KEY,
            valueType: fieldConfig.valueType,
            value: fieldValue,

            bbox: fieldData?.bbox,
            pageIndex: fieldData?.pageIndex,
            itemIndex,

            config: fieldConfig,
            data: cloneDeep(fieldData),
        });
    });

    const groupValues: any[] = Object.values(groups);
    const findPosition = (fields: FormField[]) => fields.find((f) => f.config.position != null)?.config.position;

    groupValues.sort((a: any[], b: any[]) => findPosition(a) - findPosition(b));

    // Note: This is a hack, but I really don't care anymore
    //  in case of header data only show matching result on first field
    if (prefix === '') {
        groupValues.forEach((fields) => {
            // only count field index for actual displayable fields e.g. those having a __
            let fieldIndex = 0;
            fields.forEach((field) => {
                if (!field.path.includes(GROUP_SEPARATOR)) return;

                if (
                    fieldIndex > 0 &&
                    field?.data?.confidenceExplanation?.explanationDetails?.document_matching_result
                ) {
                    field.data.confidenceExplanation.explanationDetails.document_matching_result = undefined;
                }

                fieldIndex += 1;
            });
        });
    }

    return groupValues;
};
