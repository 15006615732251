import * as React from 'react';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DocumentType } from '../../../generic_document/constants';
import DocumentAssistance from '../../../generic_document/pages/Assistance';
import ListOfServicesAssistanceHeader from '../../components/ListOfServicesAssistanceHeader';
import {
    ASSISTANCE_PATH,
    ASSISTANCE_TAB_PATH,
    CHANNEL_FINISHED_PATH,
    CHANNEL_PATH,
    CHANNEL_TESTING_PATH,
    OVERVIEW_FINISHED_PATH,
    OVERVIEW_PATH,
    OVERVIEW_TESTING_PATH,
} from '../../constants';
import {
    DELETE_LIST_OF_SERVICES,
    DISCARD_LIST_OF_SERVICES,
    GET_ASSISTANCE_OVERVIEW_DATA,
    GET_NEXT_ASSISTANCE_RECORD,
    RE_UPLOAD_FILE,
    REOPEN_FOR_ASSISTANCE,
    RETRY_STEP,
    SEND_TO_LABELING,
} from '../../queries';
import { useTranslation } from 'react-i18next';
import classnames from '../../../core_updated/utils/classnames';
import PositionAssistanceCard from '../../components/PositionAssistanceCard';
import { useAssistanceContext } from '../../../generic_document/pages/Assistance/AssistanceContext';
import PositionArticleSetCard, {
    DRAGGABLE_ITEM_TYPE,
    DropPreview,
    Separator,
} from '../../components/PositionArticleSetCard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Button from '../../../core_updated/components/Button';
import { ArticleNumberFieldConditionalComponents } from '../../../generic_document/components/ArticleNumberField';
import { useDebounce } from '../../../core/utils/hooks/useDebounce';
import { useApplicationContext } from '../../../core_updated/contexts/ApplicationContext';

const SCROLL_ANCHOR_OFFSET = 24;

export interface IPosition {
    number: IFieldData;
    text: IFieldData;
    isRelevant: IFieldData;
    rejectedBy: IFieldData;
    articleSets: IArticleSet[];
    positionIndex: number;
    pageIndex: number;
    prediction: string;
    parent: string;
    [key: string]: any;
}

export const ListOfServicesPositionsTab = ({
    record,
    filterListOfServicesPositions,
    scrollAnchorIndex,
    onScrollAnchorIndexChange,
}) => {
    const scrollRef = useRef(null);

    const handleScroll = () => {
        // relevantNodes is a list of all nodes with data-relevant="true"
        const relevantNodes = scrollRef.current.querySelectorAll('[data-scroll-anchor]');
        const offsetTop = scrollRef.current.getBoundingClientRect().top;

        let closestChildIndex = null;
        let minDistance = Infinity;

        relevantNodes.forEach((child, index) => {
            const childRect = child.getBoundingClientRect();
            const distance = Math.abs(childRect.top - offsetTop - SCROLL_ANCHOR_OFFSET);

            if (distance <= minDistance) {
                minDistance = distance;
                closestChildIndex = index;
            }
        });

        if (closestChildIndex !== null) {
            // with this if condition we prevent that when we're at the bottom of the list set the index to null
            // but rather keep it at the last index
            onScrollAnchorIndexChange(closestChildIndex);
        }
    };
    const debouncedHandleScroll = useDebounce(handleScroll, 300);

    useEffect(() => {
        // jump to the last visible scroll anchor
        const scrollAnchor = scrollRef.current.querySelectorAll('[data-scroll-anchor]')?.[scrollAnchorIndex || 0];
        scrollRef.current.scrollTo({
            top: scrollAnchor
                ? scrollAnchor.getBoundingClientRect().top -
                  scrollRef.current.getBoundingClientRect().top +
                  scrollRef.current.scrollTop -
                  SCROLL_ANCHOR_OFFSET
                : 0,
            behavior: 'instant',
        });
    }, [filterListOfServicesPositions, scrollAnchorIndex]);

    let positionsByChapter: IPosition[][] = record?.listOfServicesUnsaved?.positions.reduce((acc, item, index) => {
        item.positionIndex = index;
        // New chapter
        if (!item.parent) {
            acc.push([item]);
        }
        // push position to the last chapter
        else {
            acc[acc.length - 1].push(item);
        }
        return acc;
    }, []);

    return (
        <>
            <div className="absolute top-[53px] right-0 w-[calc(40%-42px)] h-[calc(100%-53px)] field-modal-root bg-transparent pointer-events-none"></div>
            <div
                className="flex-col w-full h-full bg-secondary-light overflow-auto p-12"
                ref={scrollRef}
                onScroll={debouncedHandleScroll}
            >
                {positionsByChapter &&
                    Object.entries(positionsByChapter).map(([pageIndex, positions], i) => {
                        return (
                            <ListOfServicesChapter
                                key={i}
                                positions={positions}
                                filterListOfServicesPositions={filterListOfServicesPositions}
                                pageIndex={pageIndex}
                                record={record}
                            />
                        );
                    })}
            </div>
        </>
    );
};

interface IFieldData {
    value?: string;
    predictionConfidence?: number;
    confidenceExplanation?: {
        translationKey: string;
        explanationDetails?: {
            confidence: number;
            field_value: string;
        };
    };
    choices?: string[]; // list of options comes from extracted data
    // ... and more but not sure if it's needed here
    [key: string]: any;
}

export interface IArticle {
    number: IFieldData;
    description: IFieldData;
    quantity: IFieldData;
    unit: IFieldData;
}

interface IArticleSet {
    id: string;
    articles: IArticle[];
    isComplete: boolean;
}

const documentConfig = {
    // TODO: this needs to come from the backend
    fields: [
        {
            name: 'number',
            valueType: 'string',
            position: 0,
            enabled: true,
            options: [
                {
                    name: 'isPartitionIdField',
                    value: true, // needed to be able to search in the master data browser without partition id
                },
                {
                    name: 'isRequired',
                    value: false,
                },
                {
                    name: 'performLookups',
                    value: true,
                },
                {
                    name: 'lookupFieldName',
                    value: 'article_id1',
                },
                {
                    name: 'lookupType',
                    value: 'ArticleLookupType',
                },
                {
                    name: 'lookupDefinitionId',
                    value: '',
                },
            ],
        },
        {
            name: 'description',
            valueType: 'string',
            position: 1,
            enabled: true,
            options: [
                {
                    name: 'isPartitionIdField',
                    value: true, // needed to be able to search in the master data browser without partition id
                },
                {
                    name: 'isRequired',
                    value: false,
                },
                {
                    name: 'performLookups',
                    value: true,
                },
                {
                    name: 'lookupFieldName',
                    value: 'description',
                },
                {
                    name: 'lookupType',
                    value: 'ArticleLookupType',
                },
                {
                    name: 'allow_invalid_ids',
                    value: true,
                },
                {
                    name: 'lookupDefinitionId',
                    value: '',
                },
            ],
        },
        {
            name: 'quantity',
            valueType: 'decimal',
            position: 2,
            enabled: true,
            options: [
                {
                    name: 'isRequired',
                    value: false,
                },
            ],
        },
        {
            name: 'unit',
            valueType: 'string',
            position: 3,
            enabled: true,
            options: [
                {
                    name: 'isRequired',
                    value: false,
                },
            ],
        },
    ],
};

const updateLookupDefinitionId = (documentConfig, newLookupDefinitionId, fieldName) => {
    // Find the field with the name 'number'
    const numberField = documentConfig.fields.find((field) => field.name === fieldName);

    if (numberField) {
        // Find the option with the name 'lookupDefinitionId'
        const lookupDefinitionIdOption = numberField.options.find((option) => option.name === 'lookupDefinitionId');

        if (lookupDefinitionIdOption) {
            // Update the value of 'lookupDefinitionId'
            lookupDefinitionIdOption.value = newLookupDefinitionId;
        }
    }
};

const ListOfServicesChapter = ({ positions, pageIndex, record, filterListOfServicesPositions }) => {
    const { t } = useTranslation('assistance');

    const rootChapterNumber = positions[0].number.value;
    const displayRootChapterHeader = !positions[0].text || positions[0].prediction === 'Position';

    // Remove positions with no text (i.e. they're placeholders)
    positions = positions.filter((position) => !!position.text);

    const [forceDisplayPosition, setForceDisplayPosition] = useState<IPosition | null>(null);

    // Filter relevant positions; pretexts should always be displayed
    const filteredPositions = positions.filter(
        (position) =>
            position.isRelevant.value === 'True' ||
            position.prediction === 'PreText' ||
            position === forceDisplayPosition
    );
    const numberRelevantPositions = positions.filter(
        (position) => position.prediction === 'Position' && position.isRelevant.value === 'True'
    ).length;
    const numberPositions = positions.filter((position) => position.prediction === 'Position').length;

    return (
        <div className={classnames('min-h-10 w-full gap-4 text-neutral-focus')} data-scroll-anchor={true}>
            <div className={classnames('flex flex-col')}>
                {
                    // Display root chapter header if it's a placeholder
                    displayRootChapterHeader && (
                        <span className="font-semibold mb-6 text-primary text-base">
                            {t('listOfServicesView.chapter') + ' ' + rootChapterNumber}
                        </span>
                    )
                }
                {(filterListOfServicesPositions ? filteredPositions : positions).map((position, i) => {
                    return (
                        <Fragment key={i}>
                            {position.prediction === 'PreText' && (
                                <span className="font-semibold mb-6 text-primary text-base">
                                    {t('listOfServicesView.chapter') + ' ' + position.number.value}
                                </span>
                            )}
                            <ListOfServicesPositionContainer
                                key={position.positionIndex}
                                setForceDisplayPosition={setForceDisplayPosition}
                                position={position}
                            />
                        </Fragment>
                    );
                })}
            </div>
            <div className={classnames('flex justify-start items-center gap-4 text-sm')}>
                <div>
                    <span className="font-semibold">{numberRelevantPositions}</span>{' '}
                    {t('listOfServicesView.relevantPositions')} {t('header.pagination.of')}{' '}
                    <span className="font-semibold">{numberPositions}</span>
                    {' ' +
                        t('listOfServicesView.foundIn') +
                        ' ' +
                        t('listOfServicesView.chapter') +
                        ' ' +
                        rootChapterNumber}
                </div>
            </div>
            <div className={classnames('grow h-px bg-border-primary mb-12 mt-6')} />
        </div>
    );
};

interface ListOfServicesPositionContainerProps {
    position: IPosition;
    setForceDisplayPosition: (ForceDisplayPosition: IPosition | null) => void;
}

const ListOfServicesPositionContainer = ({
    position,
    setForceDisplayPosition,
}: ListOfServicesPositionContainerProps) => {
    const { handlers, readOnly, record, loading } = useAssistanceContext();
    const { user } = useApplicationContext();
    const { t } = useTranslation('assistance');

    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

    const handleMove = useCallback((newIndex: number) => {
        setDraggedIndex(newIndex);
    }, []);

    const handleDrop = useCallback(
        (item) => {
            setDraggedIndex(null);

            return handlers.onUpdate({
                itemIndex: position.positionIndex,
                action: 'action:drag_article_set',
                payload: {
                    setIndex: item.index,
                    // depending on moving up or down, the dragged item will be inserted before or after the item
                    newDragSetIndex: draggedIndex > item.index ? draggedIndex - 1 : draggedIndex,
                },
            });
        },
        [draggedIndex]
    );

    const [showArticles, setShowArticles] = useState(true);

    // TROX CUSTOMIZATION: Use orderCode instead of number
    if (user?.customer?.customizationKey === 'trox') {
        if (!documentConfig.fields.find((field) => field.name === 'orderCode')) {
            const troxOrderCodeConfig = {
                name: 'orderCode',
                valueType: 'string',
                position: -1,
                enabled: true,
                options: [
                    {
                        name: 'isRequired',
                        value: false,
                    },
                ],
            };
            documentConfig.fields.push(troxOrderCodeConfig);
        }
    }

    // As long as the documentConfig is hard coded, we need to set the lookupDefinitionId from the channel's MD config
    updateLookupDefinitionId(
        documentConfig,
        record?.channel?.masterDataConfig?.articleLookupDefinitionId || '',
        'number'
    );
    updateLookupDefinitionId(
        documentConfig,
        record?.channel?.masterDataConfig?.articleLookupDefinitionId || '',
        'description'
    );
    return (
        <div className={classnames('relative flex justify-end items-start gap-4 mb-6')}>
            <PositionAssistanceCard
                number={position.number.value}
                description={position.text?.value}
                isRelevant={position.isRelevant.value === 'True'}
                reason={position.rejectedBy?.value}
                articlesCount={position.articleSets.length}
                className={classnames('sticky -top-6 w-2/3')}
                onRelevantChange={(isRelevant) => {
                    // On relevancy change, show irrelevant position for a short time even when filter is active
                    setForceDisplayPosition?.(position);
                    return handlers
                        .onUpdate({
                            action: 'action:change_relevancy',
                            itemIndex: position.positionIndex,
                            payload: { isRelevant: isRelevant },
                        })
                        .then(() => {
                            setTimeout(() => {
                                setForceDisplayPosition?.(null);
                            }, 1200);
                        });
                }}
                pageIndex={Number(position.pageIndex) + 1}
                showArticles={showArticles}
                onShowArticlesChange={setShowArticles}
                variant={position.prediction === 'PreText' ? 'pretext' : 'position'}
            />
            {position.isRelevant.value === 'True' ? (
                <DndProvider backend={HTML5Backend} context={window}>
                    <div className="w-2/5 flex flex-col gap-8 items-center sticky -top-6">
                        {draggedIndex === 0 && (
                            <DropPreview
                                index={0}
                                acceptedDropKey={`${DRAGGABLE_ITEM_TYPE}-position-${position.positionIndex}`}
                            />
                        )}

                        {position.articleSets.map((articleSet, index) => (
                            <React.Fragment key={articleSet.id}>
                                <PositionArticleSetCard
                                    className="w-full"
                                    acceptedDropKey={`${DRAGGABLE_ITEM_TYPE}-position-${position.positionIndex}`}
                                    setIndex={index}
                                    articles={articleSet.articles}
                                    onArticlesChange={(action, payload) => {
                                        return handlers.onUpdate({
                                            itemIndex: position.positionIndex,
                                            action: action, // 'action:change_article_field',
                                            payload: { setIndex: index, ...payload },
                                        });
                                    }}
                                    isComplete={articleSet.isComplete}
                                    onIsCompleteChange={(isComplete) => {
                                        return handlers.onUpdate({
                                            itemIndex: position.positionIndex,
                                            action: 'action:change_is_complete',
                                            payload: {
                                                setIndex: index,
                                                setIsComplete: isComplete,
                                            },
                                        });
                                    }}
                                    documentConfig={documentConfig}
                                    onMove={handleMove}
                                    onDrop={handleDrop}
                                    showArticles={showArticles}
                                />

                                {index < position.articleSets.length - 1 &&
                                    (draggedIndex === index + 1 ? (
                                        <DropPreview
                                            index={index + 1}
                                            acceptedDropKey={`${DRAGGABLE_ITEM_TYPE}-position-${position.positionIndex}`}
                                        />
                                    ) : (
                                        <Separator borderClassName="border-secondary">
                                            {t('listOfServices.articleSetCard.or')}
                                        </Separator>
                                    ))}
                            </React.Fragment>
                        ))}

                        {draggedIndex === position.articleSets.length && (
                            <DropPreview
                                index={position.articleSets.length}
                                acceptedDropKey={`${DRAGGABLE_ITEM_TYPE}-position-${position.positionIndex}`}
                            />
                        )}

                        <Button
                            disabled={readOnly || loading}
                            onClick={() => {
                                return handlers.onUpdate({
                                    itemIndex: position.positionIndex,
                                    action: 'action:add_article_set',
                                });
                            }}
                        >
                            {t('listOfServices.articleSetCard.addAlternative')}
                        </Button>
                    </div>
                </DndProvider>
            ) : (
                <div className="w-2/5" />
            )}
        </div>
    );
};

const ListOfServicesAssistance = (props) => {
    const [filterListOfServicesPositions, setFilterListOfServicesPositions] = useState(true);
    const [scrollAnchorIndex, setScrollAnchorIndex] = useState(0);

    const documentConfiguration = useMemo(
        () => ({
            documentType: DocumentType.ListOfServices,
            documentTypeName: 'listOfServices',
            GET_ASSISTANCE_OVERVIEW_DATA: GET_ASSISTANCE_OVERVIEW_DATA,
            GET_NEXT_ASSISTANCE_RECORD: GET_NEXT_ASSISTANCE_RECORD,
            RETRY_STEP: RETRY_STEP,
            DISCARD: DISCARD_LIST_OF_SERVICES,
            DELETE: DELETE_LIST_OF_SERVICES,
            REOPEN_FOR_ASSISTANCE: REOPEN_FOR_ASSISTANCE,
            ASSISTANCE_PATH: ASSISTANCE_PATH,
            ASSISTANCE_TAB_PATH: ASSISTANCE_TAB_PATH,
            OVERVIEW_PATH: OVERVIEW_PATH,
            OVERVIEW_FINISHED_PATH: OVERVIEW_FINISHED_PATH,
            OVERVIEW_TESTING_PATH: OVERVIEW_TESTING_PATH,
            CHANNEL_PATH: CHANNEL_PATH,
            CHANNEL_FINISHED_PATH: CHANNEL_FINISHED_PATH,
            CHANNEL_TESTING_PATH: CHANNEL_TESTING_PATH,
            RE_UPLOAD_FILE: RE_UPLOAD_FILE,
            SEND_TO_LABELING: SEND_TO_LABELING,
            components: {
                Field: [...ArticleNumberFieldConditionalComponents],
            },
            AssistanceHeader: (props) => (
                <ListOfServicesAssistanceHeader
                    {...props}
                    filterListOfServicesPositions={filterListOfServicesPositions}
                    onFilterListOfServicesPositionsChange={setFilterListOfServicesPositions}
                />
            ),
            tabs: {
                positions: (props) => {
                    return (
                        <ListOfServicesPositionsTab
                            key="positions"
                            {...{
                                ...props,
                                tab: 'positions',
                            }}
                            filterListOfServicesPositions={filterListOfServicesPositions}
                            scrollAnchorIndex={scrollAnchorIndex}
                            onScrollAnchorIndexChange={setScrollAnchorIndex}
                        />
                    );
                },
            },
            firstItemsTab: 'positions',
            defaultTab: 'positions',
        }),
        [filterListOfServicesPositions]
    );

    return <DocumentAssistance documentConfiguration={documentConfiguration} props={props} />;
};

export default ListOfServicesAssistance;
